import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import "./FloatingChat.css";

function FloatingChat() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { sender: "bot", text: "Welcome to my website! Hope it helps you get to know me better." },
  ]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const typingInterval = useRef(null);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const startTypingEffect = () => {
    // Simulate human-like typing effect
    typingInterval.current = setInterval(() => {
      setIsTyping((prev) => !prev);
    }, 500); // Change typing state every 500ms to simulate intermittent typing
  };

  const stopTypingEffect = () => {
    clearInterval(typingInterval.current);
    setIsTyping(false); // Ensure typing indicator stops
  };

  const sendMessage = async () => {
    if (input.trim()) {
      const newMessages = [...messages, { sender: "user", text: input }];
      setMessages(newMessages);
      setInput("");

      // Start the typing effect
      startTypingEffect();

      try {
        const response = await axios.post("/api/generate-content", { prompt: input });

        // Stop typing effect and show bot response
        stopTypingEffect();
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: response.data.response },
        ]);
      } catch (error) {
        console.error("Error getting response from server:", error);

        // Stop typing effect and show error message
        stopTypingEffect();
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: "There was an error getting a response. Please try again." },
        ]);
      }
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isTyping]);

  return (
    <div className="floating-chat">
      <button className="chat-toggle" onClick={toggleChat}>
        💬
      </button>
      {isOpen && (
        <div className={`chat-window ${isOpen ? "open" : ""}`}>
          <div className="chat-header">
            <h6>Chat with my virtual version!</h6>
            <button className="close-chat" onClick={toggleChat}>×</button>
          </div>
          <div className="chat-messages">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`chat-message ${msg.sender === "bot" ? "bot" : "user"}`}
              >
                {msg.text}
              </div>
            ))}
            {isTyping && (
              <div className="chat-message bot typing-indicator">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input">
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Feel free to ask me anything!"
            />
            <button onClick={sendMessage}>Send</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default FloatingChat;
